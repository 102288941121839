<template>
  <div>
    <a-card title="库存预警">
      <a-row gutter="16">
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-input-search v-model="searchForm.search" placeholder="产品编号/名称" allowClear @search="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.warehouse" placeholder="仓库" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">{{ item.name }} </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <CategoryQuerySelect v-model="searchForm.category" placeholder="产品分类" @change="search" />
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { warehouseOption } from "@/api/option";
import { inventoryWarningList } from "@/api/report";

export default {
  components: {
    CategoryQuerySelect: () => import("@/components/CategoryQuerySelect/"),
  },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (value, item, index) => index + 1,
        },

        {
          title: "产品编号",
          dataIndex: "material_number",
          customRender: (value, item, index) => {
            return item.material_item.number;
          },
        },
        {
          title: "产品名称",
          dataIndex: "material_name",
          customRender: (value, item, index) => {
            return item.material_item.name;
          },
        },
        {
          title: "产品规格",
          dataIndex: "spec",
          customRender: (value, item) => {
            return item.material_item.spec;
          },
        },
        {
          title: "产品单位",
          dataIndex: "unit",
          customRender: (value, item) => {
            return item.material_item.unit;
          },
        },
        {
          title: "库存数量",
          dataIndex: "total_quantity",
        },
        {
          title: "库存金额",
          dataIndex: "total_amount",
        },
        {
          title: "产品分类",
          dataIndex: "category",
          customRender: (value, item) => {
            return item.material_item.category_items.map((item) => item.name).join(", ");
          },
        },
        {
          title: "最高库存",
          dataIndex: "inventory_upper",
          customRender: (value, item) => {
            return item.material_item.inventory_upper;
          },
        },
        {
          title: "最低库存",
          dataIndex: "inventory_lower",
          customRender: (value, item) => {
            return item.material_item.inventory_lower;
          },
        },
        {
          title: "客户",
          dataIndex: "client_name",
          customRender: (value, item, index) => {
            return item.client_item.name;
          },
        },
        {
          title: "仓库",
          dataIndex: "warehouse_name",
          customRender: (value, item, index) => {
            return item.warehouse_item.name;
          },
        },
      ],
      searchForm: { search: "", page: 1, page_size: 15, is_warning: true },
      pagination: { current: 1, total: 0, pageSize: 15 },
      loading: false,
      items: [],

      warehouseItems: [],
    };
  },
  computed: {},
  methods: {
    initialize() {
      this.list();

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
        this.searchForm.warehouse = this.currentWarehouse;
      });
    },
    list() {
      this.loading = true;
      inventoryWarningList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
